<template>
  <NuxtLink
    class="article-card-container"
    :to="`/article/${data.slug}`"
  >
    <div
      class="article-header"
      :style="{ backgroundImage: `url(${data.thumbnail})` }"
    />
    <div class="article-content">
      <Text
        type="p3"
        weight="medium"
        class="article-type-badge mb-2"
      >
        {{ data.tag }}
      </Text>
      <Text
        type="p1"
        weight="bold"
        class="article-title"
      >
        {{ data.title }}
      </Text>
      <Text
        type="p2"
        class="content-text"
      >
        {{ contentDesc }}
      </Text>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
  import { stripHtml } from 'string-strip-html'

  import { IArticle } from '~/interfaces'

  interface IArticleCardProps {
    data: IArticle
  }

  const props = defineProps<IArticleCardProps>()

  const contentDesc = computed(() => stripHtml(props.data.content).result)
</script>

<style scoped lang="scss">
  .article-card-container {
    border: 1px solid $base500;
    min-width: 260px;

    .article-header {
      background-size: cover;
      background-position: center;
      height: 160px;
    }

    .article-type-badge {
      background: $base300;
      padding: 4px 6px;
      width: fit-content;
    }

    .article-content {
      padding: 16px;
    }

    .article-title {
      margin-bottom: 8px;
      line-clamp: 2;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .content-text {
      line-clamp: 4;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
</style>
